import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { API_BASE_URL } from '../config';

function AdminUsersList() {
  const [users, setUsers] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/users`);
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
      setError('Failed to fetch users. Please try again.');
    }
  };

  const handleDownloadQR = (qrCode, userName) => {
    const link = document.createElement('a');
    link.href = qrCode;
    link.download = `${userName}_QR_Code.png`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  return (
    <div className="container mx-auto px-4">
      <h2 className="text-2xl font-bold mb-4">User List</h2>
      <table className="min-w-full bg-white">
        <thead>
          <tr>
            <th className="py-2 px-4 border-b">Name</th>
            <th className="py-2 px-4 border-b">Surname</th>
            <th className="py-2 px-4 border-b">ID Number</th>
            <th className="py-2 px-4 border-b">Mobile Number</th>
            <th className="py-2 px-4 border-b">Email</th>
            <th className="py-2 px-4 border-b">QR Code</th>
          </tr>
        </thead>
        <tbody>
        {Array.isArray(users) && users.map((user) => (
          <tr key={user._id}>
            <td className="py-2 px-4 border-b">
              <Link to={`/admin/users/${user._id}`} className="text-blue-600 hover:underline">
                {user.name}
              </Link>
            </td>
            <td className="py-2 px-4 border-b">
              <Link to={`/admin/users/${user._id}`} className="text-blue-600 hover:underline">
                {user.surname}
              </Link>
            </td>
            <td className="py-2 px-4 border-b">
              <Link to={`/admin/users/${user._id}`} className="text-blue-600 hover:underline">
                {user.idNumber}
              </Link>
            </td>
            <td className="py-2 px-4 border-b">
              <Link to={`/admin/users/${user._id}`} className="text-blue-600 hover:underline">
                {user.mobileNumber}
              </Link>
            </td>
            <td className="py-2 px-4 border-b">
              <Link to={`/admin/users/${user._id}`} className="text-blue-600 hover:underline">
                {user.email}
              </Link>
            </td>
            <td className="py-2 px-4 border-b">
              {user.qrCode && (
                <div className="flex flex-col items-center">
                  <Link to={`/admin/users/${user._id}`}>
                    <img
                      src={user.qrCode}
                      alt={`QR Code for ${user.name}`}
                      className="w-32 h-32 object-contain cursor-pointer hover:opacity-80 transition-opacity"
                    />
                  </Link>
                </div>
              )}
            </td>
          </tr>
        ))}
        </tbody>
      </table>
    </div>
  );
}

export default AdminUsersList;