import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import { API_BASE_URL } from '../config';
import { createPayfastPayment } from '../utils/payfast';

function UserDetails() {
  const [user, setUser] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [amount, setAmount] = useState('');
  const { userId } = useParams();

  useEffect(() => {
    const fetchUserAndTransactions = async () => {
      try {
        setLoading(true);
        const [userResponse, transactionsResponse] = await Promise.all([
          axios.get(`${API_BASE_URL}/users/${userId}`),
          axios.get(`${API_BASE_URL}/transactions/${userId}`)
        ]);
        console.log('User data received:', JSON.stringify(userResponse.data, null, 2));
        setUser(userResponse.data);
        setTransactions(Array.isArray(transactionsResponse.data) ? transactionsResponse.data : []);
        setError(null);
      } catch (error) {
        console.error('Error fetching user data:', error);
        setError('Failed to fetch user data. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchUserAndTransactions();
  }, [userId]);

  const handleDownloadQR = () => {
    if (user && user.qrCode) {
      const link = document.createElement('a');
      link.href = user.qrCode;
      link.download = `${user.name}_${user.surname}_QR_Code.png`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const handlePayment = async (e) => {
    e.preventDefault();
    if (user && amount) {
      try {
        const paymentUrl = await createPayfastPayment(user, parseFloat(amount));
        window.location.href = paymentUrl;
      } catch (error) {
        console.error('Error creating payment:', error);
        setError('Error creating payment. Please try again.');
      }
    } else {
      setError('User data or amount is missing');
    }
  };

  if (loading) return <div className="text-center mt-8">Loading user data...</div>;
  if (error) return <div className="text-center mt-8 text-red-600">{error}</div>;
  if (!user) return <div className="text-center mt-8">No user data available</div>;

  return (
    <div className="container mx-auto px-4 py-8">
      <h2 className="text-3xl font-bold mb-6 text-gray-800">User Details</h2>
      <div className="bg-white shadow-md rounded-lg overflow-hidden">
        <div className="p-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <h3 className="text-xl font-semibold mb-4 text-gray-700">Personal Information</h3>
              <p><strong>Name:</strong> {user.name}</p>
              <p><strong>Surname:</strong> {user.surname}</p>
              <p><strong>ID Number:</strong> {user.idNumber}</p>
              <p><strong>Mobile Number:</strong> {user.mobileNumber}</p>
              <p><strong>Email:</strong> {user.email}</p>
              <p><strong>Company:</strong> {user.company}</p>
              <p><strong>Additional Info:</strong> {user.additionalInfo}</p>
              <p><strong>Tracking Number:</strong> {user.trackingNumber}</p>
              <p><strong>Wallet Amount:</strong> R {user.walletAmount != null ? user.walletAmount.toFixed(2) : 'N/A'}</p>
              </div>
            <div>
              <h3 className="text-xl font-semibold mb-4 text-gray-700">QR Code</h3>
              {user.qrCode ? (
                <>
                  <img src={user.qrCode} alt="User QR Code" className="w-48 h-48 mb-4" />
                  <button
                    onClick={handleDownloadQR}
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2"
                  >
                    Download QR Code
                  </button>
                </>
              ) : (
                <p>No QR Code available</p>
              )}
            </div>
          </div>
          <div className="mt-6">
            <h3 className="text-xl font-semibold mb-4 text-gray-700">Make Payment</h3>
            <form onSubmit={handlePayment} className="flex items-center">
              <input
                type="number"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                placeholder="Enter amount"
                className="border rounded py-2 px-3 mr-2"
                required
              />
              <button type="submit" className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
                Pay Now
              </button>
            </form>
          </div>
        </div>
      </div>

      <h3 className="text-2xl font-bold mt-8 mb-4 text-gray-800">Transactions</h3>
        <div className="bg-white shadow-md rounded-lg overflow-hidden">
        {transactions.length > 0 ? (
            <table className="min-w-full">
            <thead className="bg-gray-100">
                <tr>
                <th className="py-3 px-4 text-left">Date</th>
                <th className="py-3 px-4 text-left">Amount</th>
                <th className="py-3 px-4 text-left">Status</th>
                </tr>
            </thead>
            <tbody>
                {transactions.map((transaction) => (
                <tr key={transaction._id} className="border-b">
                    <td className="py-3 px-4">
                    {transaction.createdAt 
                        ? new Date(transaction.createdAt).toLocaleString('en-ZA', {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit'
                        })
                        : 'N/A'}
                    </td>
                    <td className="py-3 px-4">R {transaction.amount.toFixed(2)}</td>
                    <td className="py-3 px-4">{transaction.status}</td>
                </tr>
                ))}
            </tbody>
            </table>
        ) : (
            <p className="p-4">No transactions found for this user.</p>
        )}
        </div>
    </div>
  );
}

export default UserDetails;